var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-btn',{staticClass:"button",style:([
    {
      background: _vm.backgroundColor
    },
    {
      border: _vm.borderStyle
    },
    {
      color: _vm.textColor
    }
  ]),attrs:{"rounded":"","elevation":"0","dark":_vm.theme === 'primary',"outlined":"","loading":_vm.loading,"width":_vm.width,"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click')}}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }